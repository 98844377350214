import axios from "axios";
import { AllowProperite, JavaVersion, MinecrServer, User } from "../../types";

interface loginData {
  email: string;
  password: string;
}

export const GlobalURL = localStorage.getItem("server_adress");
//https://minecraft.indexit407.ru:8000

export const socketURL = `${GlobalURL}`;

const instance = axios.create({
  baseURL: `${GlobalURL}/api/`,
  timeout: 10000,
});

export const apiLogin = (data: loginData): any => {
  return instance.post(`users/login`, data);
};

export const apiRegister = (data: loginData): any => {
  return instance.post(`users/register`, data);
};

export const apiCurrent = (): any => {
  return instance.get(`users/current`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

/*export const apiGetAvatar = (value: string): any => {
    return instance.get(`users/getAvatar/${value}`, {
        headers: {
            'authorization': `Bearer ${localStorage.getItem('token')}`,     
        }
    });
}*/

export const apiGetAllSettings = (): any => {
  return instance.get(`settings/`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiGetVersions = (): any => {
  return instance.get(`settings/getVersions`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiRemoveJava = (id: number): any => {
  return instance.get(`settings/removeJava/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiAddJava = (java: JavaVersion): any => {
  return instance.post(`settings/addJava/`, java, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiEditJava = (java: JavaVersion): any => {
  return instance.put(`settings/editJava/${java.id}`, java, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiRemoveAllowProperite = (id: number): any => {
  return instance.get(`settings/removeAllowProperite/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiAddProperite = (value: AllowProperite): any => {
  return instance.post(`settings/addAllowProperite/`, value, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiEditProperite = (value: AllowProperite): any => {
  return instance.put(`settings/editAllowProperite/${value.id}`, value, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiAddServer = (value: MinecrServer): any => {
  return instance.post(`servers/add/`, value, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiGetAllUserServers = (): any => {
  return instance.get(`servers/`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiGetAllServers = (): any => {
  return instance.get(`servers/all`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiGetServer = (id: string): any => {
  return instance.get(`servers/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiRemoveServerFromID = (id: string) => {
  return instance.get(`servers/remove/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiRemoveServerWorldFromID = (id: string) => {
  return instance.get(`servers/removeWorld/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiStartServerFromID = (id: string) => {
  return instance.get(`servers/start/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiStopServerFromID = (id: string) => {
  return instance.get(`servers/stop/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiSendCommandToServer = (id: string, command: any) => {
  return instance.post(`servers/command/${id}`, command, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiEditServer = (id: string, value: any): any => {
  return instance.put(`servers/edit/${id}`, value, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiGetServerProperites = (id: string): any => {
  return instance.get(`servers/properites/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiCloseServerProcessFromID = (id: string) => {
  return instance.get(`servers/closeProcess/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiDownloadWorldFromID = (id: string) => {
  return instance.get(`servers/downloadWorld/${id}`, {
    responseType: "blob",
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiGetModsAndPlugins = (id: string): any => {
  return instance.get(`servers/modsOrPlugins/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiDeleteFile = (id: string, path: string, filename: string) => {
  return instance.get(`servers/delete/${id}/${path}/${filename}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const apiEditFileProperite = (id: string, param: string, value: string) => {
  return instance.post(`servers/editProperite/${id}`, { param: param, value: value} ,{
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
