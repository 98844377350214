import { MinecrServer } from "../../../../../types";
import { ActionType } from "../../types/types";
import { IState } from "./iState";

export type T_SET_AUTH_STATUS = "SET_AUTH_STATUS";
export type T_SET_GET_SERVERS_STATUS = "SET_GET_SERVERS_STATUS";
export type T_SET_GET_SETTINGS_STATUS = "SET_GET_SETTINGS_STATUS";
export type T_SET_CREATING_SERVER = "SET_CREATING_SERVER";

export const SET_AUTH_STATUS: T_SET_AUTH_STATUS = "SET_AUTH_STATUS";
export const SET_GET_SERVERS_STATUS: T_SET_GET_SERVERS_STATUS = "SET_GET_SERVERS_STATUS";
export const SET_GET_SETTINGS_STATUS: T_SET_GET_SETTINGS_STATUS = "SET_GET_SETTINGS_STATUS";
export const SET_CREATING_SERVER: T_SET_CREATING_SERVER = "SET_CREATING_SERVER";

export type ActionTypeMix =
  | ActionType<T_SET_AUTH_STATUS, { value: boolean }>
  | ActionType<T_SET_GET_SERVERS_STATUS, { value: boolean }>
  | ActionType<T_SET_GET_SETTINGS_STATUS, { value: boolean }>
  | ActionType<T_SET_CREATING_SERVER, { value: boolean }>;
